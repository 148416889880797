<template>
  <div>
    <v-overlay :value="isBusy">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>

    <v-container
      id="user-profile-view"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <material-card
            color="primary"
            icon="mdi-account-edit"
            :iconSmall="true"
          >
            <template #title>
              {{ users.user.attributes.name }}'s Profile — <small class="text-body-1">Edit your profile</small>
            </template>

            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="customers.selectedItem.description"
                      disabled
                      label="Company"
                      @click="input_onClick"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="users.user.attributes.email"
                      disabled
                      color="purple"
                      label="Email Address"
                      @click="input_onClick"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="users.user.attributes.name"
                      color="primary--text"
                      label="Name"
                      @click="input_onClick"
                    />
                  </v-col>

                  <!-- Birthdate -->
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-menu
                      ref="menuBirthDate"
                      v-model="menuBirthDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormatted"
                          label="Birth Date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          @blur="birthdate = parseDate(dateFormatted)"
                          @click="input_onClick"
                        />
                      </template>
                      <v-date-picker
                        v-model="birthdate"
                        header-color="primary"
                        color="secondary"
                        @input="menuBirthDate = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="users.user.attributes.nickname"
                      color="primary--text"
                      label="Nickname"
                      @click="input_onClick"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <maintain-address
                      v-model="userAddress"
                      :originalData="[]"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="users.user.attributes.profile"
                      label="About Me"
                    />
                  </v-col>

                  <!-- Update Profile Button -->
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      min-width="150"
                      @click="updateUser()"
                    >
                      Update Profile
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </material-card>
        </v-col>

        <!-- Profile App Card -->
        <v-col
          cols="12"
          md="4"
        >
          <app-card
            :key="profileCardRenderKey"
            class="mt-4 text-center"
          >
            <input
              v-show="false"
              id="avatar-file-upload"
              class="input-file-upload"
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/bmp"
              @change="uploadAvatar"
            >

            <!-- Default Icon - No Profile Picture Selected -->
            <v-tooltip
              v-if="profilePicture.fileUrl.length <= 0"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="rounded-circle elevation-6 mt-n12 d-inline-block"
                  color="primary"
                  x-large
                  v-bind="attrs"
                  v-on="on"
                  @click="btnClick"
                >
                  mdi-account
                </v-icon>
              </template>
              <span>Add Avatar</span>
            </v-tooltip>

            <!-- User selected Profile Picture -->
            <v-tooltip
              v-if="profilePicture.fileUrl.length > 0"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  class="rounded-circle elevation-6 mt-n12 d-inline-block"
                  width="128"
                  :src="profilePicture.fileUrl"
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                  @click="btnClick"
                />
              </template>
              <span>Change Avatar</span>
            </v-tooltip>

            <v-card-text class="text-center">
              <h6 class="text-h6 mb-2 text--secondary">
                {{ (users.isAdmin ? 'Administrator' : 'User') }}
              </h6>

              <h4 class="text-h4 mb-3 text--primary">
                {{ users.user.attributes.name }}
              </h4>

              <p class="text--secondary">
                {{ users.user.attributes.profile }}
              </p>
            </v-card-text>
          </app-card>

          <v-spacer />

          <app-card class="mt-4 text-center">
            <v-card-text>
              <strong class="mb-3 d-inline-block">PRIMARY COLOR</strong>

              <v-item-group
                v-model="colorPrimary"
                mandatory
              >
                <v-item
                  v-for="thisColor in primaryColors"
                  :key="thisColor"
                  :value="thisColor"
                >
                  <template v-slot="{ active, toggle }">
                    <v-avatar
                      :class="active && 'v-settings__item--active'"
                      :color="thisColor"
                      class="v-settings__item mx-1"
                      size="25"
                      @click="toggle"
                    />
                  </template>
                </v-item>

                <!-- User selected Primary Color -->
                <!--
                <v-menu
                  ref="menuUserSelectedPrimary"
                  v-model="menuUserSelectedPrimary"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-item
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot="{ active, toggle }">
                        <v-avatar
                          :class="active && 'v-settings__item--active'"
                          :color="userSelectedPrimary"
                          class="v-settings__item mx-1"
                          size="40"
                        />
                      </template>
                    </v-item>
                  </template>
                </v-menu>
                -->
              </v-item-group>

              <v-divider class="my-4 secondary" />

              <strong class="mb-3 d-inline-block">SECONDARY COLOR</strong>

              <v-item-group
                v-model="colorSecondary"
                mandatory
              >
                <v-item
                  v-for="thisColor in secondaryColors"
                  :key="thisColor"
                  :value="thisColor"
                >
                  <template v-slot="{ active, toggle }">
                    <v-avatar
                      :class="active && 'v-settings__item--active'"
                      :color="thisColor"
                      class="v-settings__item mx-1"
                      size="25"
                      @click="toggle"
                    />
                  </template>
                </v-item>
              </v-item-group>

              <v-divider class="my-4 secondary" />

              <strong class="mb-3 d-inline-block">ACCENT COLOR</strong>

              <v-item-group
                v-model="colorAccent"
                mandatory
              >
                <v-item
                  v-for="thisColor in accentColors"
                  :key="thisColor"
                  :value="thisColor"
                >
                  <template v-slot="{ active, toggle }">
                    <v-avatar
                      :class="active && 'v-settings__item--active'"
                      :color="thisColor"
                      class="v-settings__item mx-1"
                      size="25"
                      @click="toggle"
                    />
                  </template>
                </v-item>
              </v-item-group>

              <v-divider class="my-4 secondary" />

              <strong class="mb-3 d-inline-block">SIDEBAR BACKGROUND</strong>

              <v-item-group
                v-model="selected_gradient"
              >
                <v-item
                  v-for="(scrim, index) in gradients"
                  :key="scrim"
                  :value="index"
                  class="mx-1"
                >
                  <template v-slot="{ active, toggle }">
                    <v-avatar
                      :class="active && 'v-settings__item--active'"
                      :color="scrim"
                      class="v-settings__item"
                      size="24"
                      @click="toggle"
                    />
                  </template>
                </v-item>
              </v-item-group>

              <v-divider class="my-4 secondary" />

              <!-- Dark Theme -->
              <v-row
                align="center"
                no-gutters
              >
                <v-col cols="auto">
                  Dark Mode
                </v-col>

                <v-spacer />

                <v-col cols="auto">
                  <v-switch
                    v-model="use_dark_mode"
                    class="ma-0 pa-0"
                    color="secondary"
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-divider class="my-4 secondary" />

              <v-row
                align="center"
                no-gutters
              >
                <v-col cols="auto">
                  Sidebar Image
                </v-col>

                <v-spacer />

                <v-col cols="auto">
                  <v-switch
                    v-model="show_image"
                    class="ma-0 pa-0"
                    color="secondary"
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-divider class="my-4 secondary" />

              <strong class="mb-3 d-inline-block">IMAGES</strong>

              <v-card
                :disabled="!show_image"
                flat
              >
                <v-item-group
                  v-model="selected_image"
                  class="d-flex justify-space-between mb-3"
                >
                  <v-item
                    v-for="(img, index) in images"
                    :key="img"
                    :value="index"
                    class="mx-1"
                  >
                    <template v-slot="{ active, toggle }">
                      <v-sheet
                        :class="active && 'v-settings__item--active'"
                        class="d-inline-block v-settings__item"
                        @click="toggle"
                      >
                        <v-img
                          :src="img"
                          height="100"
                          width="50"
                        />
                      </v-sheet>
                    </template>
                  </v-item>
                </v-item-group>
              </v-card>
            </v-card-text>
          </app-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // import { get } from 'vuex-pathify'
  import { Auth, Storage } from 'aws-amplify'
  import { mapGetters } from 'vuex'
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  // Vuex
  import { get, sync } from 'vuex-pathify'
  // Components
  import MaterialCard from '@/components/MaterialCard'
  import AppCard from '@/components/app/Card'
  import MaintainAddress from '@/components/maintenance/controls/Address'
  // helpers
  import {
    DEFAULT_PRIMARY_COLOR,
    DEFAULT_SECONDARY_COLOR,
    DEFAULT_ACCENT_COLOR,
  } from '@/util/globals'

  export default {
    name: 'UserProfileView',

    components: {
      MaterialCard,
      AppCard,
      MaintainAddress,
    },

    mixins: [Proxyable],

    data () {
      return {
        show_image: true,
        use_dark_mode: false,
        selected_image: 0,
        selected_gradient: 0,
        isBusy: false,
        authUser: null,
        menuBirthDate: false,
        menuUserSelectedPrimary: false,
        birthdate: null,
        dateFormatted: null,
        profilePicture: {
          file: null,
          fileUrl: '',
        },
        profileCardRenderKey: 0,
        userSelectedPrimary: '#000000',
        colorPrimary: null,
        colorSecondary: null,
        colorAccent: null,
        primaryColors: [
          DEFAULT_PRIMARY_COLOR,
          '#F56600',
          '#0073CF',
          '#228b22',
          '#9C27b0',
        ],
        secondaryColors: [
          DEFAULT_SECONDARY_COLOR,
          '#522D80',
          '#ff9800',
          '#22228b',
          '#3cb027',
        ],
        accentColors: [
          DEFAULT_ACCENT_COLOR,
          '#009dff',
          '#e30800',
          '#8b5622',
          '#8b2222',
        ],
        menu: false,
        userAddress: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
        },
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      ...sync('app', [
        'drawer',
        'mini',
      ]),
      ...get('user', [
        'images',
        'gradients',
      ]),
      /*
      ...get('app', [
        'items',
        'version',
      ]),
      appItems: get('app/items'),
      routeName: get('route/name'),
       */
      appRules: get('app/rules'),
      users: get('user/users'),
      customers: get('customer/customers'),
    },

    watch: {
      birthdate (val) {
        this.dateFormatted = this.formatDate(val)
      },
      colorPrimary (val) {
        this.$store.dispatch('user/setPrimaryColor', val)
      },
      colorSecondary (val) {
        this.$store.dispatch('user/setSecondaryColor', val)
      },
      colorAccent (val) {
        this.$store.dispatch('user/setAccentColor', val)
      },
      show_image (val) {
        this.$store.dispatch('user/setShowImage', val)
      },
      use_dark_mode (val) {
        this.$store.dispatch('user/setDarkMode', val)
      },
      selected_image (val) {
        this.$store.dispatch('user/setImage', val)
      },
      selected_gradient (val) {
        this.$store.dispatch('user/setGradient', val)
      },
    },

    created () {
      this.initialize()
      this.getAuthUser()
    },

    methods: {
      rerenderProfileCard () {
        this.profileCardRenderKey += 1
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      btnClick () {
        document.querySelector('.input-file-upload').click()
      },
      updateProfilePicture () {
        this.isBusy = true
        // Couldn't get async await to work, had to go this route
        Auth.updateUserAttributes(this.authUser, {
          picture: this.profilePicture.file.name,
        })
          .then(() => {
            this.getProfilePicture(this.profilePicture.file.name)
          })
          .catch(err => {
            this.$store.dispatch('error/setError', { name: 'Update Profile Picture', details: err })
          })
      },
      uploadAvatar (event) {
        if (event.currentTarget.files.length > 0) {
          this.profilePicture.file = event.currentTarget.files[0]
          const picturePath = `user/${this.authUser.username}/${this.profilePicture.file.name}`
          Storage.put(picturePath, this.profilePicture.file)
            .then(() => {
              this.updateProfilePicture()
            })
            .catch(err => {
              this.$store.dispatch('error/setError', { name: 'Upload Avatar', details: err })
            })
        }
      },
      getProfilePicture (pictureName) {
        if (!pictureName) {
          if ({}.hasOwnProperty.call(this.authUser.attributes, 'picture')) {
            pictureName = this.authUser.attributes.picture
          }
        }

        if (pictureName) {
          const picturePath = `user/${this.authUser.username}/${pictureName}`
          Storage.get(picturePath)
            .then(data => {
              this.profilePicture.fileUrl = data

              this.$nextTick(() => {
                this.rerenderProfileCard()
              })
            })
            .catch(err => {
              this.$store.dispatch('error/setError', { name: 'Get Profile Picture', details: err })
              this.profilePicture.fileUrl = ''
            })
            .finally(() => {
              this.isBusy = false
            })
        } else {
          this.profilePicture.fileUrl = ''
          this.isBusy = false
        }
      },
      async updateUser () {
        this.isBusy = true
        const updatedAttributes = {}

        if ({}.hasOwnProperty.call(this.users.user.attributes, 'name')) {
          updatedAttributes.name = this.users.user.attributes.name
        }

        if (this.birthdate) {
          updatedAttributes.birthdate = this.birthdate
        }

        if ({}.hasOwnProperty.call(this.users.user.attributes, 'nickname')) {
          updatedAttributes.nickname = this.users.user.attributes.nickname
        }

        if (this.userAddress) {
          updatedAttributes.address = JSON.stringify(this.userAddress)
        }

        if ({}.hasOwnProperty.call(this.users.user.attributes, 'profile')) {
          updatedAttributes.profile = this.users.user.attributes.profile
        }

        try {
          await Auth.updateUserAttributes(this.authUser, updatedAttributes)
        } catch (err) {
          this.$store.dispatch('error/setError', { name: 'Update User', details: err })
        } finally {
          this.isBusy = false
        }
      },
      async getAuthUser () {
        this.isBusy = true
        this.authUser = await Auth.currentAuthenticatedUser()

        if ({}.hasOwnProperty.call(this.authUser.attributes, 'birthdate')) {
          this.birthdate = this.authUser.attributes.birthdate
        }

        if ({}.hasOwnProperty.call(this.authUser.attributes, 'address')) {
          const authUserAddress = this.authUser.attributes.address
          if (authUserAddress.length > 0) {
            this.userAddress = JSON.parse(authUserAddress)
          }
        }

        this.getProfilePicture()
      },
      initialize () {
        // TODO: datastore Uncaught (in promise) Error: createdAt is read-only.
        this.$store.dispatch('user/setPageName', this.$options.name)

        this.colorPrimary = this.users.selectedColors.primary
        this.colorSecondary = this.users.selectedColors.secondary
        this.colorAccent = this.users.selectedColors.accent

        if (this.users.settings) {
          this.show_image = this.users.settings.show_image
          this.use_dark_mode = this.users.settings.dark
          this.selected_image = this.users.settings.image
          this.selected_gradient = this.users.settings.gradient
        }
      },
    },
  }
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #9E9E9E !important
</style>
